@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: local("Neue Haas Grotesk Display Pro"),
    url("./fonts/825424/75e1af8f-1a4c-475a-8b53-f27e52822b6b.woff2")
      format("woff2"),
    url("./fonts/825424/2ba6fbd5-9c17-4733-af15-f49fbecc5c15.woff")
      format("woff");
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: local("Neue Haas Grotesk Display Pro"),
    url("./fonts/5549029/6e329389-9c44-48b0-8291-f918840fe862.woff2")
      format("woff2"),
    url("./fonts/5549029/dc6a6646-e0ac-4deb-b3c0-19e5dc30bf6a.woff")
      format("woff");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Italic";
  src: local("Neue Haas Grotesk Display Pro"),
    url("./fonts/5548992/ffe38a67-9745-402f-9fdf-a2348f2b8fa7.woff2")
      format("woff2"),
    url("./fonts/5548992/7428cb40-3e1e-4345-a1d5-9ec578bcd497.woff")
      format("woff");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline"),
    url("./fonts/tiempos-headline-light-italic.woff2") format("woff2");
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
.swiper-pagination-bullet-active {
  background-color: #1a1a1a !important;
}

i {
  font-family: "Tiempos Headline", sans-serif;
  font-style: normal;
}

b,
h3,
h4,
strong {
  font-weight: 500;
}

ul {
  margin-block: 0;
}

p {
  margin-block: 0;
  margin-inline: 0;
}

* {
  box-sizing: border-box;
}
